
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { OutgoingAffiliate } from '@roc/affiliate';
    import { openPopup } from '../popup';

    export default {
        name: 'TheFooter',

        components: {
            OutgoingAffiliate,
        },

        i18nOptions: { namespaces: ['footer', 'links', 'global'] },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };

